@import url('https://fonts.googleapis.com/css2?family=Jost:wght@500&display=swap');

*{
    margin:0;
    padding:0;
    font-family: 'Jost', sans-serif;
}



.banner{
    background-image: url('./Group\ 3588.png');
    background-repeat: no-repeat;
    background-size: cover;

    
}

.universities{
    background-image: url('./Rectangle\ 42.png');
    background-repeat: no-repeat;
    background-size: cover;


}


.five-star-rating {
    font-size: 0; /* Remove white space between inline-block elements */
    margin-top: 1vh;
  }
  
  .star {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 0 0.5px;
    background-color: transparent;
    clip-path: polygon(
      50% 0%,
      61% 38%,
      100% 38%,
      68% 59%,
      79% 100%,
      50% 75%,
      21% 100%,
      32% 59%,
      0% 38%,
      39% 38%
    );
  }
  
  .yellow-star {
    background-color: #FF6C00;
  }


  .container14{
    background-image: url('./blog1.png');
    background-repeat: no-repeat;
    background-size: cover;

  }

